import { render, staticRenderFns } from "./NewPointCardCard.vue?vue&type=template&id=15895932&scoped=true"
import script from "./NewPointCardCard.vue?vue&type=script&lang=js"
export * from "./NewPointCardCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15895932",
  null
  
)

export default component.exports