<template>
  <v-dialog
    :value="true"
    @click:outside="close"
    max-width="500"
    scrollable
    :persistent="processing"
  >
    <v-card>

      <v-card-title>
        {{ $t('createPointCard') }}
      </v-card-title>

      <v-stepper
        v-model="e1"
        v-if="!completed"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            {{ $t('item') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            {{ $t('customer') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="e1 > 2"
            step="3"
          >
            {{ $t('validity') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="4">
            {{ $t('distribution') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">


			<v-text-field
              outlined
              :label="$t('cardNumber')"
              v-model="remoteID"
			  :loading="processing" 
			  :disabled="processing"
			  class="mt-1"
            >
				<template #append>
					<v-icon right @click="generateCode" :disabled="processing">
						mdi-cached
					</v-icon>
				</template>
			</v-text-field>

            <v-select
              :label="$t('item')"
              outlined
              v-model="selectedItem"
              :items="pointcardItems"
              item-value="uuid"
              item-text="title"
            />

            <v-text-field
              outlined
              :label="$t('amount')"
              v-model="selectedAmount"
            />

            <v-btn
              color="primary"
              @click="e1 = 2"
              :disabled="!canProgressToStep2"
            >
              {{ $t('continue') }}
            </v-btn>

            <v-btn text @click="close">
              {{ $t('cancel') }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">

			<v-text-field
              class="mt-1"
              :label="$t('cardName')"
              v-model="cardName"
              outlined
            />

			<v-autocomplete
                :label="$t('customer')"
                v-model="customer"
                :items="customers"
                item-value="customer_id"
                item-text="address.name_line"
            	return-object
                @change="selectCustomer"
                :filter="customerFilter"
				outlined
            >
			</v-autocomplete>
            <!-- <v-text-field
              class="mt-2"
              :label="$t('name')"
              v-model="customerName"
              outlined
            />
            <v-text-field
              :label="$t('email')"
              v-model="customerEmail"
              outlined
            />
            <v-text-field
              :label="$t('phone')"
              v-model="customerPhone"
              outlined
            /> -->

            <v-btn
              color="primary"
              @click="e1 = 3"
              :disabled="!canProgressToStep3"
            >
              {{ $t('continue') }}
            </v-btn>

            <v-btn text @click="close">
              	{{ $t('cancel') }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-select
              class="mt-2"
              :label="$t('expirationMonth')"
              outlined
              :items="months"
              item-value="key"
              item-text="value"
              v-model="expirationMonth"
            />
            <v-select
              :label="$t('expirationYear')"
              outlined
              :items="years"
              v-model="expirationYear"
            />

            <v-btn
              color="primary"
              :disabled="!canProgressToStep4"
              @click="createPointCard"
              :loading="processing"
            >
              {{ $t('createPointCard') }}
            </v-btn>

            <v-btn @click="close" text>
              {{ $t('cancel') }}
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-text v-if="completed">
        {{ $t('giftcardsHaveBeenCreatedSuccessfully') }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "NewPointCardCard",
    props: {
      pointcard: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        e1: 1,
		customer: null,
		cardName: '',
		remoteID: null,
        selectedItem: null,
        selectedAmount: null,
        processing: false,
        missingRequiredInput: false,
        expirationMonth: null,
        expirationYear: null,
        completed: false,
		response: null
      }
    },
    computed: {
      months() {
        return [
          {
            key: '01',
            value: this.$t('january'),
          },
          {
            key: '02',
            value: this.$t('february'),
          },
          {
            key: '03',
            value: this.$t('march'),
          },
          {
            key: '04',
            value: this.$t('april'),
          },
          {
            key: '05',
            value: this.$t('may'),
          },
          {
            key: '06',
            value: this.$t('june'),
          },
          {
            key: '07',
            value: this.$t('july'),
          },
          {
            key: '08',
            value: this.$t('august'),
          },
          {
            key: '09',
            value: this.$t('september'),
          },
          {
            key: '10',
            value: this.$t('october'),
          },
          {
            key: '11',
            value: this.$t('november'),
          },
          {
            key: '12',
            value: this.$t('december'),
          }
        ]
      },
	  customers() {
		return this.$store.state.customers
	  },
      years() {
        return ['2023', '2024', '2025', '2026', '2027', '2028']
      },
      canProgressToStep2() {
        return this.selectedAmount !== null && this.selectedAmount !== undefined && this.remoteID !== null && this.remoteID !== undefined
      },
      canProgressToStep3() {
        return this.customer !== null && this.customer !== undefined
      },
      canProgressToStep4() {
        return this.expirationMonth !== null && this.expirationMonth !== undefined && this.expirationYear !== null && this.expirationYear !== undefined
      },
      pointcardItems() {
        return this.$store.state.items.filter(item => item.type === 'pointcard')
      },
    },
    watch: {
      selectedItem(uuid) {
        const item = this.pointcardItems.find(item => item.uuid === uuid)
        this.selectedAmount = item.price.amount
		this.cardName = item.title
      }
    },
	mounted() {
		this.selectedAmount = this.pointcard.amount
		this.expirationMonth = this.months[this.pointcard.expiryMonth].key
		this.expirationYear = this.pointcard.expiryYear

		this.generateCode()
	},
    methods: {
      close() {
        this.$emit('closed')
      },
	  customerFilter(item, queryText, itemText) {
			if(itemText.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
			if(item.phone && item.phone.toString().toLowerCase().includes(queryText.toString().toLowerCase())) {
				return itemText
			}
			if(item.email && item.email.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
	  },
	  generateCode() {
		this.processing = true
		 this.$store.dispatch('generateCode').then(result => {
			this.remoteID = result.generateCode
		}).finally(() => {
			this.processing = false
		})
	  },
	  selectCustomer(customer) {
		this.customer = customer
	  },
	  createPointCard() {
        this.processing = true
        this.$store.dispatch('createPointCard', {
          pc: {
            item: this.selectedItem,
            amount: parseFloat(this.selectedAmount),
            customer: this.customer.uuid,
			cardName: this.cardName,
            expiryYear: parseInt(this.expirationYear),
            expiryMonth: parseInt(this.expirationMonth),
			remoteID: this.remoteID.toString(),
			item: this.selectedItem
          }
        })
		.then(response => {
			this.response = response.createPointCard
          	this.completed = true
        }).finally(() => {
			if (this.response !== null) {
				this.$store.commit('updateActionSuccess', {
					message: 'Success',
					subMessage: 'The Point card has been issued and sent.'
				})
			}else {
				this.$store.commit('updateActionError', {
					message: 'Failure',
					subMessage: 'Something went wrong while trying to create point card'
				})
			}
			this.processing = false
			this.response = null
			this.close()
        })
      },
    }
  }
</script>

<style scoped>

</style>
